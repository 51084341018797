<!-- 用能及电费报表 -->
<template>
  <div id="cost-report">
    <div class="tree">
      <div class="tree-title">变配电站</div>
      <el-tree
        ref="tree"
        :default-expand-all="false"
        :show-checkbox="false"
        :data="treeData"
        :check-on-click-node="false"
        :props="defaultProps"
        node-key="id"
        @node-click="handleCheck"
      ></el-tree>
    </div>
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>用能及电费报表</span>
      </div>
      <div class="content">
        <ul class="tabs">
          <div class="flex-row">
            <li
              v-for="(item, index) in tabsData"
              :key="index"
              :class="select === index ? 'select' : ''"
              @click="switchTabs(index)"
            >
              {{ item }}
            </li>
          </div>
          <el-checkbox v-model="checked">显示费用</el-checkbox>
        </ul>
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="formData">
            <el-form-item label="日期:">
              <el-date-picker
                type="date"
                v-model="formData.date1"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <div class="flex-row">
                <div class="hour">
                  <el-select v-model="formData.region" placeholder="">
                    <el-option label="0" value="0"></el-option>
                  </el-select>
                  <span>时~</span>
                </div>
                <div class="hour">
                  <el-select v-model="formData.region" placeholder="">
                    <el-option label="0" value="0"></el-option>
                  </el-select>
                  <span style="margin-left: 9px">时</span>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-arrow-left"
                >上一日</el-button
              >
              <el-button type="primary"
                >下一日<i class="el-icon-arrow-right el-icon--right"></i
              ></el-button>
              <el-button type="primary" icon="el-icon-search">搜索</el-button>
              <el-button type="primary" icon="el-icon-download">导出</el-button>
            </el-form-item>
          </el-form>
        </div>

        <el-table class="table" :data="tableData">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            align="center"
            prop="name"
            label="回路名称"
            width="120"
          >
          </el-table-column>
          <el-table-column align="center" label="00时">
            <el-table-column prop="one" align="center" label="电费" width="120">
            </el-table-column>
            <el-table-column prop="two" align="center" label="电费" width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="01时">
            <el-table-column
              prop="three"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="four"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="02时">
            <el-table-column
              prop="five"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
            <el-table-column prop="six" align="center" label="电费" width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="03时">
            <el-table-column
              prop="seven"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="eight"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="04时">
            <el-table-column
              prop="nine"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
            <el-table-column prop="ten" align="center" label="电费" width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="05时">
            <el-table-column
              prop="eleven"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="twelve"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="06时">
            <el-table-column prop="one" align="center" label="电费" width="120">
            </el-table-column>
            <el-table-column prop="two" align="center" label="电费" width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="07时">
            <el-table-column
              prop="three"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="four"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="08时">
            <el-table-column
              prop="five"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
            <el-table-column prop="six" align="center" label="电费" width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="09时">
            <el-table-column
              prop="seven"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="eight"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="10时">
            <el-table-column
              prop="nine"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
            <el-table-column prop="ten" align="center" label="电费" width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="11时">
            <el-table-column
              prop="eleven"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="twelve"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="12时">
            <el-table-column
              prop="nine"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="eight"
              align="center"
              label="电费"
              width="120"
            >
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { eventApi } from "@/api/event.js";
export default {
  name: "",

  data() {
    return {
      formData: {
        name: "",
      },
      tabsData: ["日报", "月报", "年报"],
      tableData: [
        {
          name: "*主进线柜",
          one: "168",
          two: "",
          three: "40",
          four: "",
          five: "42",
          six: "",
          seven: "50",
          eight: "",
          nine: "50",
          ten: "",
          eleven: "50",
          twelve: "",
        },
        {
          name: "*主进线柜",
          zero: "20",
          one: "50",
          two: "42",
          three: "40",
          four: "50",
          five: "42",
          six: "40",
          seven: "50",
          eight: "42",
          nine: "50",
          ten: "42",
          eleven: "50",
          twelve: "42",
        },
        {
          name: "*主进线柜",
          zero: "20",
          one: "50",
          two: "42",
          three: "40",
          four: "50",
          five: "42",
          six: "40",
          seven: "50",
          eight: "42",
          nine: "50",
          ten: "42",
          eleven: "50",
          twelve: "42",
        },
      ],
      select: 0,
      requestParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      checkedNodes: [],
      defaultProps: {
        children: "children",
        id: "id",
        label: "label",
      },
      treeData: [
        {
          id: 1,
          label: "配电室",
          children: [
            {
              id: 11,
              label: "高压侧",
            },
            {
              id: 12,
              label: "低压侧",
            },
            {
              id: 13,
              label: "变压器",
            },
            {
              id: 14,
              label: "直流屏",
            },
          ],
        },
        {
          id: 2,
          label: "强变",
        },
        {
          id: 3,
          label: "柱变",
        },
        {
          id: 4,
          label: "强电间",
          children: [
            {
              id: 15,
              label: "高压侧",
            },
            {
              id: 16,
              label: "低压侧",
            },
            {
              id: 17,
              label: "变压器",
            },
            {
              id: 18,
              label: "直流屏",
            },
          ],
        },
      ],
    };
  },

  mounted() {
    // this.queryOperation();
  },

  methods: {
    changeSize(pageSize) {
      this.requestParam.pageNum = pageSize;
      this.queryOperation();
    },
    switchTabs(index) {
      this.select = index;
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryOperation();
    },
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      // this.updateSelectAllState();
      console.log(this.checkedNodes);
    },
    queryOperation() {
      eventApi.operation(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
  },
};
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#cost-report {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/routerImg.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}
/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}
.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}
::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}
::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}
::v-deep.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #03183f;
}
.main {
  width: 1280px;
  padding: 20px;
  box-sizing: border-box;
}
.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.title img {
  width: 20px;
  height: 20px;
}
.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}
/* tabs */
.tabs {
  width: 1336px;
  background-color: rgb(4, 25, 66);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tabs li {
  width: 85px;
  height: 40px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
  font-family: Microsoft YaHei;
  color: #e8f4ff;
  text-align: center;
  line-height: 40px;
}
.tabs .select {
  background-color: #002c6c;
  color: #ffc74a;
}
/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
  margin: 18px 0 20px 0;
}
.query-form :deep() .el-form {
  height: 40px;
}
.form :deep() .el-icon-date {
  color: #aed6ff;
}
.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}
.form :deep() .el-form-item {
  margin: 0;
  margin-right: 8px;
}
.hour :deep() .el-select {
  width: 56px;
  padding: 0;
}
.hour span {
  color: #aed6ff;
  margin: 0 5px 0 8px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
}
/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead.is-group th.el-table__cell {
  background: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}
.table :deep() .el-table__cell {
  padding: 4px 0;
}
.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}
::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid rgb(19, 43, 83) !important;
}
::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}
.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table__fixed-right::before {
  height: 0px;
}
::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}
/* 滚动条样式 */

/* 更改 el-table 横向滚动条的样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234e;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>
